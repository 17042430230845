body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --red: #dc3545;
  --redActive: rgb(255, 202, 202);
  --redBox: rgba(220, 53, 70, 0.6);
}

/* navbar */
#navLink {
  color: white;
}

#navLink:hover {
  color: var(--redActive);
}
#navLinkActive {
  color: var(--redActive);
  text-decoration: underline;
  text-underline-offset: 5px;
  font-weight: bold;
}

#myBanner {
  background-color: var(--red);
}

#fixed {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* contatti */
#gmap_canvas {
  width: 100vw;
  height: 50vh;
}
#contact {
  height: 70vh;
  background: rgba(77, 77, 77, 0.4) url(/public/img/contact.jpg);
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  display: flex;
  justify-content: center;
  align-items: center;
}
#redDiv {
  color: white;
  padding: 5%;
  border-radius: 1em;
  box-shadow: 0 0 25px 0 rgba(188, 188, 188, 0.9);
  width: 80%;
  max-width: 850px;
  background-color: var(--redBox);
  backdrop-filter: saturate(180%) blur(3px);
}
@media only screen and (min-width: 450px) {
  #redDiv {
    font-size: 1.3em;
  }
  #whiteDiv {
    font-size: 2em;
  }
}

/* servizi */
#service {
  height: 70vh;
  background: rgba(77, 77, 77, 0.4) url(/public/img/service.jpg);
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  display: flex;
  justify-content: center;
  align-items: center;
}

#myUl {
  text-indent: -14px;
  margin-left: 10px;
}
li {
  list-style: none;
}

.bi-caret-right-fill {
  color: var(--red);
}

/* chi siamo */
.rounded {
  box-shadow: 0.5em 0.5em 1em rgba(58, 58, 58);
}

@media only screen and (max-width: 770px) {
  #mobileDirection {
    flex-direction: column-reverse;
  }
}

/* home */

#carouselCap {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#carouselCap h2 {
  font-size: 3em;
  font-weight: bolder;
}

#carouselImg {
  filter: grayscale(26%) saturate(63%) opacity(90%);
  -webkit-filter: grayscale(26%) saturate(63%) opacity(90%);
  -moz-filter: grayscale(26%) saturate(63%) opacity(90%);
  height: 80vh;
}

#odcImg {
  width: 30vw;
}

#carBtn {
  font-weight: bold;
  margin-top: 0.5em;
}

#carBtn:hover {
  background-color: rgb(255, 202, 202, 0.8);
  color: whitesmoke;
}

#cookie-banner {
  border: 1px solid rgb(36, 36, 36);
  background-color: rgba(212, 212, 212, 0.9);
  position: sticky;
  bottom: 16px;
  margin: 16px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 770px) {
  #odcImg {
    width: 70vw;
  }
  #carouselImg {
    height: 40vh;
  }
  #carouselCap h2 {
    font-size: 1.5em;
  }
  #carBtn {
    font-size: 0.8em;
  }
}
